import { QueueTaskStatuses, TaskCategories } from 'enums/taskCategories';
import { CreatedTaskResponseProps, UpdatedTaskResponseProps } from 'store/tasks/task.types';

export const isQueueGroupedTask = (data: CreatedTaskResponseProps) =>
  data?.task &&
  data.task.subCategory === 'QUEUE' &&
  data.task.category !== TaskCategories.Appointment &&
  data.task.patientStatus === QueueTaskStatuses.MIF_INCOMPLETE &&
  !data.task.assignedToInfo;

export const checkToRemoveQueueGroupedTask = (data: UpdatedTaskResponseProps) =>
  data.updatedData.patientStatus !== QueueTaskStatuses.MIF_INCOMPLETE ||
  data.updatedData.subCategory !== 'QUEUE' ||
  data.updatedData.assignedToInfo;
