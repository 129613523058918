import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import BulkEditContent from 'components/modals/BulkEditContent';
import { getHideClose, getModalSize } from 'components/modals/BulkEditContent/bulkEdit.settings';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocation } from 'react-router';
import { selectBulkEdit } from 'store/bulkEdit/bulkEditSlice';
import { openModal } from 'store/modal/modalSlice';

const AssignTasksButton: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { bulkEdits } = useAppSelector(selectBulkEdit);
  const bulkEditCount = bulkEdits.length;

  const handleClick = () => {
    dispatch(
      openModal({
        modalContent: <BulkEditContent />,
        size: getModalSize(location.pathname),
        hideClose: getHideClose(location.pathname)
      })
    );
  };

  return (
    <Common.Button onClick={handleClick} color="blue" size="sm" disabled={!bulkEditCount}>
      Assign {bulkEditCount > 1 && bulkEditCount} task{bulkEditCount === 1 ? '' : 's'}
    </Common.Button>
  );
};

export default AssignTasksButton;
