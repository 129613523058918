import { useEffect, useMemo, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import { AvailabilityFilterType } from 'enums/availability';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import { EventTypes, RepeatOptions, TimeZoneOptions } from 'models/event.types';
import { Controller, ControllerRenderProps, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { datetime, RRule } from 'rrule';
import {
  selectCalendarTimezone,
  selectEventId,
  selectEventInstanceId,
  setEventId,
  setEventInstanceId,
  useGetEventQuery,
  useGetShiftTypesQuery,
  useLazyGetEventQuery
} from 'store/calendar/calendarSlice';
import { closeModal } from 'store/modal/modalSlice';
import { selectUser } from 'store/user/userSlice';
import { ObjectValues } from 'utils/common/types';

import { normalizeResponse } from './createEvent.settings';
import type { CreateEventFormProps } from './createEventForm.types';
import EventCategorySelect from './EventCategorySelect';
import { EventCategoryFieldNames } from './EventCategorySelect/eventCategorySelect.types';
import EventSummary from './EventSummary';
import ShiftTypeSelect from './ShiftTypeSelect';
import Ends from '../Ends';
import {
  convertEventTimeToDate,
  formatTime,
  getDateTime,
  getRecurringOptions,
  getRrule
} from '../manageAvailability.settings';
import { StyledDayPicker } from '../manageAvailability.styled';
import { FormValues } from '../manageAvailability.types';
import TimePeriod from '../TimePeriod';

const today = new Date();

const defaultShiftTime = [
  {
    start: dayjs().format(DateFormat.HH_mm),
    end: dayjs().add(5, 'minutes').format(DateFormat.HH_mm)
  }
];

const selectCreateEventFormState = createSelector(
  [selectCalendarTimezone, selectEventId, selectEventInstanceId, selectUser],
  (calendarTimezone, eventId, eventInstanceId, user) => ({
    eventId: eventId || eventInstanceId,
    calendarTimezone,
    eventInstanceId: eventInstanceId,
    userId: user._id
  })
);

const CreateEventForm = ({
  isLoading,
  eventType,
  saveData,
  setIsEventRecurringChanged
}: CreateEventFormProps) => {
  const { eventId, eventInstanceId, calendarTimezone, userId } = useAppSelector(
    selectCreateEventFormState
  );
  const { id: physicianId = '' } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const [recurringDays, setRecurringDays] = useState<Date[]>([]);
  const [showCategoryFilters, setShowCategoryFilters] = useState(false);

  const { data: eventDetails, isLoading: isLoadingEventDetails } = useGetEventQuery(
    eventId ? { eventId } : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );
  const [getEventInstance, { data: recurringEventDetails }] = useLazyGetEventQuery();

  const { data: shiftTypes } = useGetShiftTypesQuery();

  const values = useMemo(
    () =>
      normalizeResponse({
        timezone: calendarTimezone,
        shiftTypes,
        eventDetailsResponse: eventDetails,
        recurringEventDetailsResponse: recurringEventDetails
      }),
    [calendarTimezone, shiftTypes, eventDetails, recurringEventDetails]
  );

  const methods = useForm<FormValues>({
    defaultValues: {
      shiftStart: {
        day: today,
        month: today
      },
      shiftTime: defaultShiftTime,
      repeats: RepeatOptions.DOES_NOT_REPEAT,
      ends: { option: 'never', date: today, dayPickerMonth: today },
      eventTypes: []
    },
    values
  });

  const { control, handleSubmit, watch, setValue, getValues, formState, setError } = methods;
  const { errors, dirtyFields, touchedFields, isValid } = formState;

  const isTimeOff = watch('eventTypes')?.find((type) => type.value === 'time-off');
  const selectedTime = formatTime(watch('shiftTime.0.start'), watch('shiftTime.0.end'));

  const isSubmitDisabled =
    !isValid ||
    isLoading ||
    !isEmpty(errors) ||
    (isEmpty(dirtyFields) && isEmpty(touchedFields)) ||
    !getValues('shiftStart')?.day;
  const isCurrentUser = !physicianId || userId === physicianId;

  const handleClose = () => {
    dispatch(closeModal());
    dispatch(setEventId(''));
    dispatch(setEventInstanceId(''));
  };

  const handleMonthChange = (month: Date) => {
    setValue('shiftStart.month', month);
    setValue('ends.dayPickerMonth', month);
  };

  const handleChangeRepeats = (
    value: ObjectValues<typeof RepeatOptions>,
    field: ControllerRenderProps<FormValues, 'repeats'>
  ) => {
    if (value !== RepeatOptions.DOES_NOT_REPEAT && !watch('ends.option')) {
      setValue('ends.option', 'never');
    }
    field.onChange(value);
  };

  const onSubmit = (data: FormValues) => {
    /**
     * If the eventTypes array contains the "specialized" type
     * And there is no filter or exclusion selected with this type
     * We should show error about the need to select a filter or exclusion for this type
     */
    const specializedEventType =
      data.eventTypes &&
      data.eventTypes.find((item) => item.label === AvailabilityFilterType.Specialized);
    const hasFilters =
      Array.isArray(data.categoryFilters) &&
      data.categoryFilters.find((item) => item.category === 'specialized');
    const hasExclusion =
      Array.isArray(data.categoryExclusions) &&
      data.categoryExclusions.find((item) => item.category === 'specialized');

    if (specializedEventType && !hasFilters && !hasExclusion) {
      return setError('eventTypes', {
        type: 'filter',
        message:
          'If the Specialized type is selected, filters or exclusions of the same type must be added'
      });
    }
    saveData(data);
    setIsEventRecurringChanged(!!dirtyFields.ends);
  };

  useEffect(() => {
    if (eventInstanceId) getEventInstance({ eventId: eventInstanceId });
  }, [eventInstanceId, getEventInstance]);

  /**
   * @description
   * This useEffect hook is responsible for handling the appearance of recurring days in the calendar.
   * It watches for changes in the 'repeats' and 'shiftStart.day' values.
   *
   * If 'repeats' is set to 'DOES_NOT_REPEAT', it clears the recurringDays state.
   *
   * If 'repeats' is set to any other value, it generates recurring dates based on the selected day, repeat and ends options.
   *
   * If 'ends.option' is set to 'on', it creates a recurring event with an end date.
   * If 'ends.option' is set to 'after' and 'ends.count' is greater than 0, it creates a recurring event with an end count.
   * If 'ends.option' is set to 'never' and 'ends.dayPickerMonth' is defined, it generates recurring dates for the next two months.
   * This is done due to performance issues when generating all dates without an end date.
   *
   * The hook returns a cleanup function that unsubscribes from the watch subscription.
   */
  useEffect(() => {
    const subscription = watch((value) => {
      const startFromFirstEvent =
        eventDetails?.start.dateTime &&
        dayjs(value.shiftStart?.day).isSame(dayjs(value.ends?.dayPickerMonth));

      // If the user edits recurring events, we should start showing the event from the first one
      const startDayForRecurring = startFromFirstEvent
        ? convertEventTimeToDate(
            eventDetails?.start.dateTime,
            TimeZoneOptions.UTC,
            calendarTimezone
          )
        : value.shiftStart?.day;

      if (value.repeats && value.shiftStart?.day) {
        if (value.repeats === RepeatOptions.DOES_NOT_REPEAT) {
          setRecurringDays([]);
        } else {
          if (value.ends?.option && value.ends?.option === 'on') {
            const rruleDay = startDayForRecurring || value.shiftStart.day;
            const endsDateForNewShift = value.ends?.date
              ? dayjs(
                  getDateTime(
                    value.ends?.date,
                    dayjs(value.shiftStart.day).format(DateFormat.HH_mm),
                    DateFormat.YYYY_MM_DD_HH_mm_ss
                  )
                ).toDate()
              : new Date();
            const endsDate = value.ends?.date ? value.ends?.date : new Date();

            const rruleEndTime = eventId ? endsDate : endsDateForNewShift;
            // create a recurring event with end date
            const rule = new RRule({
              ...getRrule({
                repeats: value.repeats,
                day: rruleDay,
                endTime: rruleEndTime
              })
            });
            setRecurringDays(rule.all());
          } else if (
            value.ends?.option &&
            value.ends.option === 'after' &&
            value.ends?.count &&
            value.ends.count > 0
          ) {
            const shiftStartDateTime = startDayForRecurring || value.shiftStart.day;
            // set the time to 12:00 PM to avoid issues with changing the timezone syndrome
            shiftStartDateTime.setHours(12);

            // create a recurring event with end count
            const rule = new RRule({
              ...getRrule({
                repeats: value.repeats,
                day: shiftStartDateTime,
                count: value.ends?.count
              })
            });

            setRecurringDays(rule.all());
          } else if (value.ends?.option && value.ends?.option === 'never') {
            const newPickedMonth = value.ends.dayPickerMonth || value.shiftStart.day;
            const shiftStartDateTime = dayjs(value.shiftStart.day).toDate();
            // set the time to 12:00 PM to avoid issues with changing the timezone syndrome
            shiftStartDateTime.setHours(12);

            const shiftStartDate = {
              year: dayjs(value.shiftStart.day).get('year'),
              month: dayjs(value.shiftStart.day).get('month'),
              date: dayjs(value.shiftStart.day).get('date')
            };

            const endDate = dayjs(newPickedMonth).add(3, 'month');
            const shiftEndDate = {
              year: endDate.get('year'),
              month: endDate.get('month'),
              date: endDate.get('date')
            };

            const rule = new RRule({
              ...getRrule({ repeats: value.repeats, day: shiftStartDateTime })
            });
            const startTime = datetime(
              shiftStartDate.year,
              shiftStartDate.month,
              shiftStartDate.date
            );
            const endTime = datetime(shiftEndDate.year, shiftEndDate.month, shiftEndDate.date);

            setRecurringDays(rule.between(startTime, endTime));
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [calendarTimezone, eventDetails?.start.dateTime, eventDetails?.start.timeZone, watch]);

  useEffect(() => {
    const floaterType = shiftTypes?.find((item) => item.name.toLocaleLowerCase() === 'floater');
    const isFloaterSelected = eventDetails?.shiftTypes?.find(
      (item) => item._id === floaterType?._id
    );

    if (eventDetails?.type && !isFloaterSelected && eventType !== EventTypes.TIME_OFF) {
      setShowCategoryFilters(true);
    }
  }, [eventDetails?.shiftTypes, eventDetails?.type, eventType, shiftTypes]);

  const isEventTypesFilterError = errors.eventTypes && errors.eventTypes?.type === 'filter';
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} role="form">
        <div className="flex gap-4">
          <div className="w-full">
            <Controller
              name="shiftStart"
              control={control}
              rules={{ validate: (value) => dayjs(value?.day).isValid() || 'Invalid date' }}
              render={({ field }) => (
                <StyledDayPicker
                  mode="single"
                  data-testid="day-picker"
                  modifiers={{ recurring: recurringDays }}
                  modifiersClassNames={{ recurring: 'recurring' }}
                  selected={watch('shiftStart.day')}
                  onSelect={(date) => {
                    // Set the selected date to the current time for recurring events
                    date?.setHours(dayjs().hour());
                    date?.setMinutes(dayjs().minute());
                    date?.setSeconds(dayjs().second());

                    field.onChange({
                      day: date,
                      month: date
                    });
                  }}
                  startMonth={today}
                  month={dayjs(watch('shiftStart.month')).toDate()}
                  onMonthChange={handleMonthChange}
                  disabled={isLoadingEventDetails || { before: today }}
                  formatters={{
                    formatWeekdayName: (weekday) => dayjs(weekday).format(DateFormat.ddd)
                  }}
                />
              )}
            />
          </div>

          <div className="w-full" data-testid="shift-summary">
            <EventSummary
              isLoading={isLoadingEventDetails}
              isEditing={!!eventId}
              isTimeOff={!!isTimeOff}
              selectedTime={selectedTime}
              selectedDay={watch('shiftStart.day')}
              repeats={watch('repeats')}
            />

            <div className="flex flex-col py-4" data-testid="shift-types">
              <span className="mb-2 text-base font-semibold">
                Select which services {isCurrentUser ? 'you' : 'they'} are available for
              </span>
              <ShiftTypeSelect
                type={eventType}
                defaultShiftTime={defaultShiftTime}
                setShowCategoryFilters={setShowCategoryFilters}
              />
              {!isEventTypesFilterError && (
                <ErrorMessage
                  errors={errors}
                  name="eventTypes"
                  render={({ message }) => <p className="mt-1 text-sm text-red">{message}</p>}
                />
              )}
            </div>

            {showCategoryFilters && (
              <div className="mb-4 flex flex-wrap gap-2">
                <EventCategorySelect name={EventCategoryFieldNames.FILTERS} />
                <EventCategorySelect name={EventCategoryFieldNames.EXCLUSIONS} />
                {isEventTypesFilterError && (
                  <ErrorMessage
                    errors={errors}
                    name="eventTypes"
                    render={({ message }) => <p className="mt-1 text-sm text-red">{message}</p>}
                  />
                )}
              </div>
            )}

            {/* Allow user select time only for breaks */}
            {!isTimeOff && (
              <div className="mb-4" data-testid="event-time-period">
                <p className="mb-2 text-base font-semibold">
                  What hours are you available for these services?
                </p>

                <TimePeriod isLoading={isLoadingEventDetails} errors={errors} />
              </div>
            )}

            <div className="mb-4" data-testid="shift-repeats">
              <p className="mb-2 text-base font-semibold">Repeats</p>
              <Controller
                name="repeats"
                control={control}
                render={({ field }) =>
                  isLoadingEventDetails ? (
                    <div className="h-8 w-full animate-pulse rounded-md bg-slate-200" />
                  ) : (
                    <Common.SelectAlt
                      hideSuccessState
                      size="sm"
                      value={field.value}
                      options={getRecurringOptions(watch('shiftStart.day'))}
                      onChange={(value) => handleChangeRepeats(value, field)}
                      dataTestId="shift-repeats-select"
                      ref={field.ref}
                      onBlur={field.onBlur}
                    />
                  )
                }
              />
            </div>

            {watch('repeats') !== RepeatOptions.DOES_NOT_REPEAT && <Ends errors={errors} />}
          </div>
        </div>

        <div className="flex gap-2">
          <Common.Button
            color="white-alt"
            className="w-full justify-center"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Common.Button>
          <Common.Button
            color="blue"
            className="w-full justify-center"
            isLoading={isLoading}
            disabled={isSubmitDisabled}
          >
            {eventId
              ? eventType === EventTypes.TIME_OFF
                ? 'Update time off'
                : 'Update shift'
              : eventType === EventTypes.TIME_OFF
                ? 'Add time off'
                : 'Add shift'}
          </Common.Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateEventForm;
